import React from 'react';
import Profile from './profile';
import WorkExperience from './exp';
import '../styles/resume.css';

const Resume = () => {
    return (
        <div className="container">
            <header>
                <h1>Joshua P</h1>
                <h2>Software Engineer - Web App Dev & Data Analytics</h2>
                <p>Kolathur, Chennai, Tamil Nadu</p>
                <p>Email: <a href="mailto:rulingstarjoshua@gmail.com">rulingstarjoshua@gmail.com</a></p>
                <p>Phone: +91 80560 47876</p>
            </header>
            <Profile />
            <WorkExperience />
        </div>
    );
};

export default Resume;
