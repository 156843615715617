import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'; 
import '../styles/exp.css'; // For WorkExperience-specific styles

// Register the necessary components
Chart.register(ArcElement, Tooltip, Legend);

const WorkExperience = () => {
    const experienceData = [
        {
            startDate: "Jan 2024",
            endDate: "",
            duration: "0.9",
            jobTitle: "Web Application Developer",
            companyName: "Self Employed Consultant",
            clients: "1. AhobilamHotels.com 2. NalamOrganics.com",
            skills: "Web App Dev, Data Analytics",
            tools: "WordPress, Microsoft Power BI, PostgreSQL, VS Code editor",
            programmingLanguages: "JavaScript, DAX, SQL"
        },
        {
            startDate: "May 2022",
            endDate: "Nov 2023",
            duration: "1.7",
            jobTitle: "Senior Software Engineer",
            companyName: "United Techno Info Systems",
            clients: "1. elf Comestics, US 2. Town & Country Living, US",
            skills: "Data Analytics, React JS",
            tools: "Microsoft Power BI, JIRA, PostgreSQL, VS Code editor",
            programmingLanguages: "JavaScript, DAX, SQL"
        },
        {
            startDate: "Feb 2020",
            endDate: "May 2022",
            duration: "2.4",
            jobTitle: "Technical Specialist",
            companyName: "Acetech Machinery Components Pvt Ltd",
            clients: "Govt. Client GCC Greater Chennai Corporation",
            skills: "Stakeholder Coordination, Data Analytics, ETL Extract Transform Load",
            tools: "Microsoft Power BI, JIRA, PostgreSQL, Apache Nifi",
            programmingLanguages: "JavaScript, DAX, SQL"
        },
        {
            startDate: "Feb 2019",
            endDate: "Dec 2019",
            duration: "0.11",
            jobTitle: "Business Development Manager",
            companyName: "Cookie Man (Australian Foods India Pvt)",
            clients: "",
            skills: "Sales & Marketing, Legal Documentation, Franchise renewals",
            tools: "",
            programmingLanguages: ""
        },
        {
            startDate: "Nov 2017",
            endDate: "Feb 2019",
            duration: "1.4",
            jobTitle: "Business Development Manager",
            companyName: "Swaaadle Tech Pvt. Ltd.",
            clients: "",
            skills: "Sales & Marketing, Lead generation & creation, Pricing, After Support",
            tools: "",
            programmingLanguages: ""
        },
        {
            startDate: "May 2015",
            endDate: "Feb 2016",
            duration: "0.1",
            jobTitle: "Senior Process Associate",
            companyName: "Tata Consultancy Services",
            clients: "Morgan Stanley, US",
            skills: "Network Monitoring, Linux Server Maintenance",
            tools: "SockEye",
            programmingLanguages: ""
        },
        {
            startDate: "Oct 2012",
            endDate: "Apr 2014",
            duration: "1.7",
            jobTitle: "NOC Engineer",
            companyName: "iOPEX Technologies",
            clients: "",
            skills: "Network Monitoring, Linux Server Maintenance",
            tools: "Zabbix",
            programmingLanguages: ""
        }
    ];

    console.log("Experience Data: ", experienceData);

    const getSkillsData = () => {
        const skillsCount = {};
        const toolsCount = {};
        const programmingLanguagesCount = {};
        const totalEntries = experienceData.filter(experience => new Date(experience.startDate) >= new Date("2020-02-01")).length;

        experienceData.forEach(experience => {
            if (new Date(experience.startDate) >= new Date("2020-02-01")) {
                // Process skills
                if (experience.skills) {
                    const skills = experience.skills.split(',').map(skill => skill.trim()).filter(skill => skill);
                    skills.forEach(skill => {
                        skillsCount[skill] = (skillsCount[skill] || 0) + 1;
                    });
                }

                // Process tools
                if (experience.tools) {
                    const tools = experience.tools.split(',').map(tool => tool.trim()).filter(tool => tool);
                    tools.forEach(tool => {
                        toolsCount[tool] = (toolsCount[tool] || 0) + 1;
                    });
                }

                // Process programming languages
                if (experience.programmingLanguages) {
                    const languages = experience.programmingLanguages.split(',').map(language => language.trim()).filter(language => language);
                    languages.forEach(language => {
                        programmingLanguagesCount[language] = (programmingLanguagesCount[language] || 0) + 1;
                    });
                }
            }
        });

        const skillsData = Object.keys(skillsCount).map(skill => ({
            name: skill,
            percentage: ((skillsCount[skill] / totalEntries) * 100).toFixed(1)
        }));

        const toolsData = Object.keys(toolsCount).map(tool => ({
            name: tool,
            percentage: ((toolsCount[tool] / totalEntries) * 100).toFixed(1)
        }));

        const languagesData = Object.keys(programmingLanguagesCount).map(language => ({
            name: language,
            percentage: ((programmingLanguagesCount[language] / totalEntries) * 100).toFixed(1)
        }));

        return [...skillsData, ...toolsData, ...languagesData];
    };

    const skillsData = getSkillsData();

    const chartData = {
        labels: skillsData.map(skill => skill.name),
        datasets: [{
            data: skillsData.map(skill => skill.percentage),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
            hoverOffset: 4,
        }],
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: 'index',
                callbacks: {
                    label: (tooltipItem) => {
                        const index = tooltipItem.dataIndex;
                        const skillName = skillsData[index].name;
                        const relatedExperience = experienceData.find(experience => {
                            return experience.skills.includes(skillName) || 
                                   experience.tools.includes(skillName) || 
                                   experience.programmingLanguages.includes(skillName);
                        });
                        return `${skillName}: ${skillsData[index].percentage}% | ${relatedExperience ? `${relatedExperience.jobTitle} at ${relatedExperience.companyName} (${relatedExperience.duration} yrs)` : ''}`;
                    }
                }
            }
        },
    };

    return (
        <div className="experience">
            <h2>Work Experience 9.4Yrs</h2>
            <table border="1">
                <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Duration (Yrs)</th>
                        <th>Job Title</th>
                        <th>Company Name</th>
                        <th>Clients</th>
                        <th>Skills</th>
                        <th>Tools</th>
                        <th>Programming Languages</th>
                    </tr>
                </thead>
                <tbody>
                    {experienceData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.startDate}</td>
                            <td>{item.endDate}</td>
                            <td>{item.duration}</td>
                            <td>{item.jobTitle}</td>
                            <td>{item.companyName}</td>
                            <td>{item.clients}</td>
                            <td>{item.skills}</td>
                            <td>{item.tools}</td>
                            <td>{item.programmingLanguages}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h2>Dev Skills Overview</h2>
            <Pie data={chartData} options={options} />
        </div>
    );
};

export default WorkExperience;
