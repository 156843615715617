import React from 'react';
import '../styles/profile.css'; // For Profile-specific styles

const Profile = () => {
    return (
        <section className="profile">
            <h3><i className="fas fa-user-circle"></i> Objective</h3>
            <p>A dedicated professional seeking career opportunities with a focus on personal and organizational growth.</p>
        </section>
    );
};

export default Profile;
